











import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

import Listings from '../components/Listings.vue';

@Component({
  components: {
    Listings,
  },
})
export default class LandForSale extends Mixins(View) {
  private listingFilter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.ResidentialLand,
    ],
    PageSize: 9,
  });
}
